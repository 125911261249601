import React from 'react'
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import privacy_policy from "../../assets/documents/Privacy_Policy_for_Nuestro_Loans.pdf"
import tnc from "../../assets/documents/Terms_and_Conditions_NUESTRO.docx"
import Footer from '../../components/footer/english';
import NavBar from '../../components/navbar/english';

const LegalEn = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh',  backgroundColor:'rgb(232, 0, 120)' }}>
        <NavBar/>
        <div className="container container-wrapper" style={{ flex: 1 }}>
            
            
                
                
            <h1 className="mt-3 mb-3 legal-title text-center grow-effect" style={{fontFamily: 'RockoUltraFLF', color:'rgb(33, 67, 136)', fontSize:'60px'}}>Disclosures</h1>
<div className="legal ps-5 pt-3 slide-in-right" style={{color:'#ffffff', fontFamily:'AvenirBold', fontSize:'30px'}}>
<a href={tnc} target="_blank" rel="noopener"  style={{color:'#ffffff', fontWeight:'600'}}>Term and Conditions</a><br/>    
<a href={privacy_policy} target="_blank" rel="noopener"  style={{color:'#ffffff', fontWeight:'600'}}>Privacy Policy</a><br/>
</div>
    </div>
    <Footer/>
    </div>
  )
}

export default LegalEn;