import React from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import FooterEn from "../../components/footer/english";
import NavbarEn from "../../components/navbar/english";
import { Container } from "react-bootstrap";
import "../../styles/style.css"

const FaqEn = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <NavbarEn />
      <Container fluid className="faq_main">
        <div className="faq_video">
        <iframe src="https://www.youtube.com/embed/nlI8YhxgQI0?si=S01vOV8qfqkYAEwB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div class="row">
          <div class="col-4">
            <div
              id="simple-list-example"
              class="d-flex flex-column gap-2 simple-list-example-scrollspy py-4 ps-lg-5 ps-2 link_box"
            >
              <h2 style={{color:'rgb(232, 0, 120)', fontFamily:'RockoUltraFLF'}}>FAQs</h2>
              <a class="p-1 rounded faq_link" href="#simple-list-item-1">
                Loan Products
              </a>
              <a class="p-1 rounded faq_link" href="#simple-list-item-2">
                Application Process
              </a>
              <a class="p-1 rounded faq_link" href="#simple-list-item-3">
                Loan Approval and Processing Time
              </a>
              <a class="p-1 rounded faq_link" href="#simple-list-item-4">
                Interest Rates and Terms
              </a>
              <a class="p-1 rounded faq_link" href="#simple-list-item-5">
                Security and Privacy
              </a>
              <a class="p-1 rounded faq_link" href="#simple-list-item-6">
                Additional Payment Information
              </a>
              <a class="p-1 rounded faq_link" href="#simple-list-item-7">
                Contact Information
              </a>
            </div>
          </div>
          <div class="col-8 p-lg-5 p-3">
            <div
              data-bs-spy="scroll"
              data-bs-target="#simple-list-example"
              data-bs-offset="0"
              data-bs-smooth-scroll="true"
              class="scrollspy-example faq_scroll"
              tabindex="0"
            >
              <h4 className="faq_heading" id="simple-list-item-1">Loan Products</h4>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOneOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      1.1 What types of loans does Nuestro offer?
                    </button>
                  </h2>
                  <div
                    id="collapseOneOne"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                      Prestamo Migrante: This one's for new clients, or Sin ITIN
                      clients
                      <br />
                      Prestamo Premier: For Nuestro’s existing clients
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOneTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      1.2 What is Nuestro Prestamo Migrante?
                    </button>
                  </h2>
                  <div
                    id="collapseOneTwo"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                      Nuestro Prestamo Migrante! This Product was carefully
                      designed for our community qualifying with SSN, ITIN o
                      solo con pasaporte! Whether you need it to expand your
                      business, for an auto repair, bills, legal, funeral,
                      travel expenses, or anything else, Nuestro is here to help
                      begin the journey.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOneThree"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                    >
                      1.3 What is Nuestro Premier?
                    </button>
                  </h2>
                  <div
                    id="collapseOneThree"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                      Nuestro Prestamo Premier is for our existing clientele
                      that, after paying the first loan, need or want another!
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOneFour"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                    >
                      1.4 Can I get a second loan with Nuestro?
                    </button>
                  </h2>
                  <div
                    id="collapseOneFour"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                      Nuestro only permits one active loan per customer.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOneFive"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                    >
                      1.5 I live outside of the state, can I still apply?
                    </button>
                  </h2>
                  <div
                    id="collapseOneFive"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                      Nuestro only permits Tennessee residents to apply to our
                      loan products.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOneSix"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                    >
                      1.6 I wasn’t approved for the amount I applied for, why?
                    </button>
                  </h2>
                  <div
                    id="collapseOneSix"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                      If Nuestro is unable to offer the requested amount, we
                      will follow up with a counteroffer.
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="faq_heading" id="simple-list-item-2">Application Process</h4>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwoOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      2.1 How can I apply for a loan with Nuestro?
                    </button>
                  </h2>
                  <div
                    id="collapseTwoOne"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                      Applying for a loan is simple and convenient. You can
                      complete the application process online through our
                      website. Just fill out the required information and submit
                      your application.
                      <br />
                      <br />
                      Link -{" "}
                      <a
                        href="https://nuestro-loans.turnkey-lender.com/Lending#/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://nuestro-loans.turnkey-lender.com/Lending#/terms
                      </a>
                      <br />
                      <br />
                      If you need help, stop by our offices!
                      <br />
                      <br />
                      Nashville - 444 Metroplex Dr Unit B-225, Nashville, TN
                      37211
                      <br />
                      <br />
                      Shelbyville - 405 Thompson St, Shelbyville, TN 37160
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwoTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      2.2 How long does it take to complete the application?
                    </button>
                  </h2>
                  <div
                    id="collapseTwoTwo"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                      30 minutes to 1 hour! Stop by on your lunch break!
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                    >
                      2.3 What documents are required for the loan application?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                      The documents required may vary depending on the type of
                      loan you're applying for.
                      <br />
                      <br />
                      Commonly requested documents include:
                      <ul>
                        <li>Photo ID</li>
                        <li>Proof of Domicile</li>
                        <li>Proof of Employment / Pay</li>
                        <li>Three Most Recent Bank Statements</li>
                        <li>Valid Bank Account</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="faq_heading" id="simple-list-item-3">Loan Approval and Processing Time</h4>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThreeOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      3.1 How long does it take for a loan to be approved?
                    </button>
                  </h2>
                  <div
                    id="collapseThreeOne"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                      Once we have ALL documentation and information, please
                      give us 24 – 48 hours to review the solicitation.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThreeTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      3.2 What happens if my application is denied?
                    </button>
                  </h2>
                  <div
                    id="collapseThreeTwo"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                      You will receive an SMS and an email notification to
                      notify you of the decision, once it has been made.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThreeThree"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                    >
                      3.3 Once approved, how long does it take to receive the
                      funds?
                    </button>
                  </h2>
                  <div
                    id="collapseThreeThree"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                      Once approved and signed, you will receive the money via
                      Direct Deposit into your connected bank account within
                      approximately 48 hours.
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="faq_heading" id="simple-list-item-4">Interest Rates and Terms</h4>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFourOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      4.1 What are the interest rates for Nuestro loans?
                    </button>
                  </h2>
                  <div
                    id="collapseFourOne"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                      Interest rates and terms vary depending on the type of
                      loan, and other factors. We offer competitive rates and
                      flexible terms to suit your needs. Specific details will
                      be provided during the application process.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFourTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      4.2 What are the repayment terms available?
                    </button>
                  </h2>
                  <div
                    id="collapseFourTwo"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                      Custom terms are able to chosen, from 3, 4, 5, and 6
                      months for repayment length.
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="faq_heading" id="simple-list-item-5">Security and Privacy</h4>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFiveOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      5.1 How is my personal information protected during the
                      loan application process?
                    </button>
                  </h2>
                  <div
                    id="collapseFiveOne"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                      We take the security and privacy of your information
                      seriously. We adhere to strict privacy policies to ensure
                      your information remains confidential.
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="faq_heading" id="simple-list-item-6">Additional Payment Information</h4>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSixOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      6.1 How often can I make payments, and what are the
                      available payment methods?
                    </button>
                  </h2>
                  <div
                    id="collapseSixOne"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                      Payments are required every 15 days, additional payments
                      are welcome.
                      <br />
                      <br />
                      Autocharge charges your connected bank account
                      automatically, however, please feel free to call us to
                      request different available payment methods.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSixTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      6.2 Can I reschedule my loan payments?
                    </button>
                  </h2>
                  <div
                    id="collapseSixTwo"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                      Please call us at{" "}
                      <a
                        href="tel:615-800-6181"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        615-800-6181
                      </a>{" "}
                      for more information regarding rescheduling.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSixThree"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                    >
                      6.3 Can I make additional payments or pay off my loan
                      early?
                    </button>
                  </h2>
                  <div
                    id="collapseSixThree"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                      Yes! To make an additional payment, login to your loan
                      account and make a payment. Alternatively, give us a call
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSixFour"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                    >
                      6.4 Are there any fees for paying off my loan early?
                    </button>
                  </h2>
                  <div
                    id="collapseSixFour"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                      It is best practice to check your loan agreement for any
                      PrePayment Penalties, however, our Prestamo Migrante and
                      Prestamo Premier products do NOT have
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="faq_heading" id="simple-list-item-7">Contact Information</h4>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSevenOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      7.1 How can I contact Nuestro for further assistance?
                    </button>
                  </h2>
                  <div
                    id="collapseSevenOne"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                      If you have any questions or need assistance, please don't
                      hesitate to contact our customer support team. You can
                      reach us by phone, email, or you can visit us.
                      <br />
                      <br />
                      Link -{" "}
                      <a
                        href="https://www.nuestro.loans/contactus"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://www.nuestro.loans/
                      </a>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSevenTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      7.2 How can I schedule a visit to the Nuestro Office?
                    </button>
                  </h2>
                  <div
                    id="collapseSevenTwo"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                      Our doors are open!
                      <br />
                      <br />
                      Nashville:
                      <br />
                      Monday - Friday
                      <br />
                      9:00 am – 5:00pm
                      <br />
                      <br />
                      Shelbyville:
                      <br />
                      Monday - Friday
                      <br />
                      4:00pm- 7:00pm
                      <br />
                      Saturday
                      <br />
                      10:00pm – 2:00pm
                    </div>
                  </div>
                </div>
              </div>
              <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            </div>
          </div>
        </div>
      </Container>
      <FooterEn />
    </div>
  );
};

export default FaqEn;
