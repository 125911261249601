import React from "react";
import "../../styles/style.css";
import "../../styles/main.css";
import NavbarEn from "../../components/navbar/english";

import getStarted from "../../assets/images/get_started.png";
import m1 from "../../assets/images/masonary/m12.jpg";
import s from "../../assets/images/s.png";
import t from "../../assets/images/t.png";
import u from "../../assets/images/u.png";
import v from "../../assets/images/v.png";
import whynuestro1 from "../../assets/images/product.png";
import whynuestro2 from "../../assets/images/ssnitin.png";
import whynuestro3 from "../../assets/images/bilingual.png";
import whynuestro4 from "../../assets/images/culture.png";
import banner_bg from "../../assets/images/banner_bg_copy.png";
import mobile_banner_bg from "../../assets/images/banner_bg.png";
import banner from "../../assets/images/home_loan_banner.png";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import FooterEn from "../../components/footer/english";
import click from "../../assets/gifs/click.webm";
import footer_novus from "../../assets/images/Footer_Nuestro_Novus.jpg";

const test = () => {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isMacOS = /Macintosh/.test(navigator.userAgent);

  const shouldRenderVideo = !isIOS && !isMacOS;

  return (
    <div>
      <NavbarEn />
      <div>
        <Container fluid>
          <Row>
          <Col sm={12} md={6} xs={12} className="ps-5">
              <div>
                <img src={banner} width="100%" />
              </div>
            </Col>
            <Col sm={12} md={6} xs={12} style={{ zIndex: 50 }}>
              <div className="banner_text">
                <h1>
                
                Test
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <FooterEn />
    </div>
  );
};

export default test;
