import React from 'react'
import "../../styles/style.css"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import tiktok from "../../assets/icons/tiktok.webp";
import instagram from "../../assets/icons/instagram.webp";
import facebook from "../../assets/icons/facebook.webp";
import youtube from "../../assets/icons/youtube.webp";
import twitter from "../../assets/icons/twitter.webp";
import call from "../../assets/icons/telephone.png";
import equal_housing_lender from "../../assets/images/equal-housing-lender-white.png";

const FooterEn = () => {
  return (
    <>
    <footer className="main-footer">

<div className="container">
    <div className="widgets-section">
        <div className="row clearfix align-items-center">

            <div className="footer-column col-lg-4 col-md-4 col-sm-12 ps-lg-5">
                <div className="footer-widget">

                    <div className="footer-service-list" style={{lineHeight:'20px'}}>
                        <a target="_blank"
                                href="https://app.termly.io/document/privacy-policy/bd9b6b40-9f99-45eb-a7ac-9656f6fc20ed">Privacy Policy</a><br/>
                                <a target="_blank"
                                href="https://app.termly.io/document/terms-of-use-for-saas/ae898e7a-4d4f-49dc-b1bf-666b4437a680">Terms and Conditions</a><br/>
                                <a target="_blank"
                                href="https://app.termly.io/document/eula/0f64760c-8bc5-49e0-91b0-f4ae41099d35">EULA</a><br/>
                                <a target="_blank"
                                href="https://app.termly.io/document/disclaimer/1807630b-4a48-4ce8-921e-cdc823014549">Disclaimer</a><br/>
                                <a target="_blank"
                                href="https://app.termly.io/document/acceptable-use-policy/b99a5bff-ce7a-4d43-bffe-c79109dc2553">Acceptable Use Policy</a><br/>
                                <a target="_blank"
                                href="/disclosures" style={{fontWeight:'bold'}}>DISCLOSURES</a><br/>
                    </div>

                </div>
            </div>

            <div className="text-center col-lg-4 col-md-4 col-sm-12 pt-3">

                <div className="social-links">
                    <a href="https://www.tiktok.com/@nuestrowallet" target="_blank" rel="noopener noreferrer"><img alt="tiktok_logo" src={tiktok} /></a>
                    
                    <a href="https://www.instagram.com/nuestrowallet" target="_blank" rel="noopener noreferrer"><img alt="instagram_logo" src={instagram}/></a>
                    
                    <a href="https://www.facebook.com/nuestrowallet/" target="_blank" rel="noopener noreferrer"><img alt="facebook_logo" src={facebook}/></a>
                    
                    <a href="https://twitter.com/NuestroWallet" target="_blank" rel="noopener noreferrer"><img alt="twitter_logo" src={twitter}/></a>
                    
                    <a href="https://www.youtube.com/channel/UCLoqN-UhpkP1qZEyIDCdUbw" target="_blank" rel="noopener noreferrer"><img alt="youtube_logo" src={youtube}/></a>
                    
                </div>
                <a href="https://maps.app.goo.gl/pWnYTPRGGAXVzJuC6" target="_blank" style={{color:'#ffffff', fontSize:'13px'}}>444 Metroplex Dr Unit B-225, Nashville, TN 37211</a><br/>
                <a href="tel:(615) 800-6181" style={{color:'#ffffff', fontSize:'12px', textDecoration:'none'}}><img src={call} width="15px"/> (615) 800-6181</a>
            </div>

            <div className="footer-bottom col-lg-4 col-md-4 col-sm-12">
                <div>
                    <div className="text-left pt-5">
                    <div>
                <img src={equal_housing_lender} width="20%" className="py-1"/>
              </div>
                        <div className="copyright mb-3">© 2024 Nuestro Financial LLC</div>
                        <div className="copyright mb-3">Nuestro Financial, NMLS #2468593,<br/> in partnership with Novus Home Mortgage,<br/> a division of Ixonia Bank. We are headquartered at<br/><a href="https://maps.app.goo.gl/yvUFk6jKZq4QVpLP8">20225 Water Tower blvd, Suite 400, Brookfield, WI 53045</a><br/>Toll free <a href="tel:(844) 337-2548">(844) 337-2548.</a> NMLS No. 423065<br/>(<a target="_blank" href="https://nmlsconsumeraccess.org">www.nmlsconsumeraccess.org</a>)</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

</footer>
    </>
  )
}

export default FooterEn;