// Navbar.jsx

import { useRef, useState } from "react";
import { FaBars, FaTimes, FaSearch } from "react-icons/fa";
import { Container, Row, Col } from 'react-bootstrap';
import { RiMenu4Fill } from "react-icons/ri";
import "../../styles/main.css";
import loans_logo from "../../assets/images/Nuestro_Novus_logo.png";
import nuestro_icon from "../../assets/icons/nuestro_icon.png";
import en_flag from "../../assets/images/english_flag.png";

function NavbarEs() {
  const navRef = useRef();
  const [language, setLanguage] = useState("english");

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === "english" ? "spanish" : "english"));
  };

  return (
    <header style={{zIndex:100, position:'relative'}}>
      <Container>
      <div>
        <img src={loans_logo} alt="nuestro loans logo" className="logo_img"/>
        <nav ref={navRef} className="ms-lg-5 ps-lg-5 pb-4">
          <img src={nuestro_icon} alt="nuestro loans logo" width="80rem" className="mobile_logo" />
          <a href="/">Inicio</a>
          <a href="/es/contactus">Contáctenos</a>
          <a href="/es/disclosures">Divulgaciones</a>
          <a href="/es/financial-literacy">Educación financiera</a>
          <a className="language-toggle" onClick={toggleLanguage}>
            <a href="/en"><img src={en_flag} alt="English flag" /></a>
        </a>
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>
        {/* <div className="search-bar-container ms-4">
          Add your search bar HTML here
          <input type="text" placeholder="Busqueda..." />
          <FaSearch className="search-icon" />
        </div> */}
        <button className="nav-btn" onClick={showNavbar}>
          <RiMenu4Fill />
        </button>
      </div>
      </Container>
    </header>
  );
}

export default NavbarEs;
